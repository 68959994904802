<template>
  <div
    v-if="loaded"
    :id="'video-item-' + data.id">
    <validation-observer
      ref="user-form"
      tag="form"
      class="videos-list-item mb-2"
      @keydown.enter.prevent>

      <div class="item-thumbnail item-cell-top">
        <template v-if="data.image && (typeof data.image !== 'string')">
          <img
            class="block"
            :src="filePreview"
            :alt="data.title + ' thumbnail'" />
        </template>
        <template v-else>
          <img
            class="block"
            :src="data.image ? data.image : require('@/assets/images/video-default-thumbnail.jpg')"
            :alt="data.title + ' thumbnail'" />
        </template>
      </div>
      <div class="item-description item-cell-top">
        <div class="form-group full-width mb-0 ">
          <label for="description">
            Video description
          </label>
          <textarea
            name="description"
            rows="3"
            class="small full-width"
            v-model="data.description"
            placeholder="Video description"
            :disabled="!!data.is_archived"  />
        </div>
      </div>
      <div class="item-about-author item-cell-top">
        <div class="form-group full-width mb-0">
          <label for="approver-comments">
            Approver Comments
          </label>
          <textarea
            name="approver-comments"
            rows="3"
            v-model="data.approve_comment"
            class="full-width small"
            :disabled="!!data.is_archived" />
        </div>
      </div>
      <div class="item-cell-top item-meta">
        <span>User: <strong>{{ data.video.name }}</strong></span>
        <span>Uploaded: <strong>{{ formatDate(data.created_at) }}</strong></span>
        <span>Size: <strong>{{ formatBytes(data.file_size) }}</strong></span>
      </div>
      <div class="item-actions item-cell-top pr-0 flex flex-column">
        <strong
          v-if="!!data.is_archived"
          class="text-center text-danger mb-2">
          Archived
        </strong>
        <f-button
          class="mx-auto"
          :theme="'outline'"
          @click.prevent="toggleDetailsVisible">
          {{ toggleDetailsVisibleLabel }}
        </f-button>
        <f-button
          v-if="!data.is_archived"
          class="mx-auto mt-2"
          :theme="'secondary'"
          @click.prevent="validateForm">
          Save
        </f-button>
      </div>

      <div class="flex full-width">
        <div class="form-group half-width mr-1 mb-1">
          <label for="title" class="for-required">
            Title
          </label>

          <validation-provider
            rules="required"
            name="title"
            v-slot="{ errors }">
            <input
              class="full-width small"
              type="text"
              v-model="data.title"
              :state="errors.length ? 'invalid' : null"/>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="form-group half-width ml-1 mb-1">
          <label for="ref-code" class="for-required">
            Ref Code
          </label>

          <validation-provider
            rules="required"
            name="ref_code"
            v-slot="{ errors }">
            <input
              class="full-width small"
              type="text"
              v-model="data.ref_code"
              :state="errors.length ? 'invalid' : null"/>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

      </div>

      <transition name="fade">
        <div
          v-if="isDetailsVisible"
          class="item-details mt-2">
          <div class="flex">
            <div class="item-details-part item-video no-shrink flex flex-column mr-auto">
              <template v-if="data.is_archived">
                <div class="no-video pl-2">
                  <strong>This video is no longer available</strong>
                </div>
              </template>
              <template v-else>
                <video
                  v-if="videoDetails && videoDetails.url"
                  :id="'video-' + data.video_id"
                  controls
                  :type="data.file_type"
                  v-on="data.duration > 0 ? {} : { loadeddata: updateVideoDuration }">
                  <source :src="videoDetails.url">
                  Your browser does not support the video tag.
                </video>
              </template>
              <div class="">
                <div class="form-group mb-2 mt-2">
                  <label class="pl-0">
                    User data:
                  </label>
                  <div class="flex flex-wrap flex-justify-content-between">
                    <template v-if="data.video.meta_data">
                      <div class="form-group full-width mb-1">
                        <label for="first-name">
                          First Name
                        </label>

                        <validation-provider
                          rules="required"
                          name="first-name"
                          v-slot="{ errors }">
                          <input
                            class="full-width small"
                            type="text"
                            v-model="data.video.meta_data.first_name"
                            :state="errors.length ? 'invalid' : null"/>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>

                      <div class="form-group full-width mb-1">
                        <label for="last-name">
                          Last Name
                        </label>

                        <validation-provider
                          rules="required"
                          name="last-name"
                          v-slot="{ errors }">
                          <input
                            class="full-width small"
                            type="text"
                            v-model="data.video.meta_data.last_name"
                            :state="errors.length ? 'invalid' : null"/>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>

                      <div class="form-group full-width mb-1">
                        <label for="email" class="for-required">
                          E-mail
                        </label>

                        <validation-provider
                          rules="required|email"
                          name="email"
                          v-slot="{ errors }">
                          <input
                            class="full-width small"
                            type="text"
                            v-model="data.video.meta_data.email"
                            :state="errors.length ? 'invalid' : null"/>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>

                      <div class="form-group full-width mb-1">
                        <label for="mobile">
                          Mobile
                        </label>

                        <validation-provider
                          rules=""
                          name="mobile"
                          v-slot="{ errors }">
                          <input
                            class="full-width small"
                            type="text"
                            v-model="data.video.meta_data.mobile"
                            :state="errors.length ? 'invalid' : null"/>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>

                      <div class="form-group full-width mb-1">
                        <label for="town">
                          Town
                        </label>

                        <validation-provider
                          rules=""
                          name="town"
                          v-slot="{ errors }">
                          <input
                            class="full-width small"
                            type="text"
                            v-model="data.video.meta_data.town"
                            :state="errors.length ? 'invalid' : null"/>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>

                      <span
                        v-if="data.video.meta_data.creator_first_name || data.video.meta_data.creator_last_name"
                        class="full-width">
                      </span>
                      <span
                        v-if="data.video.meta_data.creator_first_name"
                        class="user-info-item">
                        Parent First Name: <strong>{{ getMetadata('creator_first_name') }}</strong>
                      </span>
                      <span
                        v-if="data.video.meta_data.creator_last_name"
                        class="user-info-item">
                        Parent Last Name: <strong>{{ getMetadata('creator_last_name') }}</strong>
                      </span>
                    </template>
                    <template v-else>
                      No information
                    </template>
                  </div>
                </div>
                <div class="form-group mb-1">
                  <label for="about-you">
                    About You
                  </label>
                  <textarea
                    name="about-you"
                    rows="3"
                    class="small full-width"
                    v-model="data.about_you"
                    placeholder="About You"
                    :disabled="!!data.is_archived" />
                </div>
              </div>
            </div>
            <div class="item-details-part flex flex-column ml-auto">
              <div
                v-if="!data.is_archived"
                class="item-category flex full-width">
                <div class="form-group half-width mr-1 mb-2">
                  <label for="parent-category">Category</label>

                  <v-select
                    class="mb-0"
                    :options="allCategories"
                    v-model="parentCategory"
                    :searchable="true"
                    placeholder="Select category"
                    label="name">
                  </v-select>
                </div>

                <div class="form-group half-width mb-2">
                  <label for="child-category">Sub Category</label>

                  <v-select
                    class="mb-0 ml-1"
                    :disabled="!parentCategory || parentCategory.children.length < 1"
                    :options="!parentCategory ? [] : parentCategory.children"
                    v-model="childCategory"
                    :searchable="true"
                    placeholder="Select sub category"
                    label="name">
                  </v-select>
                </div>

                  <f-button
                  class="ml-2 mt-auto mb-3"
                  :theme="'outline'"
                  :disabled="!parentCategory"
                  @click.prevent="addCategory">
                  Add
                </f-button>
              </div>

              <div class="item-selected-categories mb-2">
                <div class="form-group mb-0">
                  <label for="tag">
                    Selected categories
                  </label>
                  <v-select
                  :disabled="!!data.is_archived"
                    :searchable="false"
                    :noDrop="true"
                    class="mb-0"
                    :options="allCategories"
                    v-model="data.categories"
                    :multiple="true"
                    placeholder="Selected categories"
                    label="label">
                  </v-select>
                </div>
              </div>

              <div class="item-tag">
                <div class="form-group mb-3">
                  <label for="tag">
                    Tag
                  </label>
                  <v-select
                    class="mb-0"
                    :disabled="!!data.is_archived"
                    :options="allAvailableTags"
                    v-model="data.tags"
                    :searchable="true"
                    :multiple="true"
                    :taggable="true"
                    placeholder="Select tag"
                    label="name">
                  </v-select>
                </div>
              </div>

              <div class="item-approvement">
                <div class="form-group">
                  <label for="status">
                    Status
                  </label>

                  <v-select
                    class="mb-0"
                    :disabled="!!data.is_archived"
                    :options="allStatuses"
                    v-model="data.status"
                    :reduce="(status) => status.id"
                    :searchable="true"
                    placeholder="Select status"
                    label="name">
                  </v-select>
                </div>
              </div>
              <div class="form-group mb-1">
                <div>
                  <label for="video-thumbnail">
                    Video thumbnail
                  </label>
                  <div class="flex">
                    <f-file-input
                      class="mr-auto"
                      id="videoThumbnail"
                      :label="data.image ? fileNameToDisplay : 'Set video thumbnail'"
                      accept=".jpg, .jpeg, .png"
                      :isWide="true"
                      :onChange="setThumbnail"/>
                    <f-button
                      v-if="data.image"
                      theme="close"
                      class="px-1"
                      @click.prevent="removeThumbnail" />
                  </div>
                </div>
                <div
                  v-if="data.image && data.image.size && data.image.size > 100000"
                  class="invalid-feedback ml-0 pl-2 mt-0">
                  File size exceeds 100KB
                </div>
                <div class="text-size-small pl-2">
                  File should not be bigger than 400x225px, size should not exceed 100KB.
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </validation-observer>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';

export default {
  name: 'videos-list-item',
  props: {
    video: {
      type: Object,
      required: true
    },
    allStatuses: {
      type: Array,
      required: true
    },
    allCategories: {
      type: Array,
      required: true
    },
    allTags: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    toggleDetailsVisibleLabel () {
      if (this.isDetailsVisible) {
        return 'Hide';
      }

      return 'Show';
    },
    allAvailableTags () {
      let selectTagsIds = this.data.tags.map(item => item.id);
      return this.allTags.filter(item => selectTagsIds.indexOf(item.id) < 0);
    },
    fileNameToDisplay () {
      if (this.data.image && (typeof this.data.image === 'string')) {
        let pathArray = this.data.image.split('/');
        return pathArray.pop();
      }

      if (this.data.image && this.data.image.name) {
        return this.data.image.name;
      }

      return '';
    },
    filePreview () {
      if (!this.data.image || (typeof this.data.image === 'string')) {
        return null;
      }

      return URL.createObjectURL(this.data.image);
    }
  },
  data () {
    return {
      data: {
        id: 0,
        about_you: '',
        approve_comment: '',
        arn: '',
        categories: [],
        created_at: '',
        description: '',
        duration: 0,
        file_type: '',
        image: '',
        status: '',
        ref_code: null,
        tags: [],
        title: '',
        video: {
          name: ''
        },
        video_id: 0,
        is_archived: false
      },
      loaded: false,
      parentCategory: null,
      childCategory: null,
      isDetailsVisible: false,
      loadVideo: false,
      videoDetails: null,
      videoDetailsLoaded: false,
      originalStatus: null
    }
  },
  mounted () {
    Vue.set(this, 'data', this.video);
    Vue.set(this.data, 'tags', ListUtils.sortByKey(this.data.tags, 'name'));
    this.addLabelsForCategories(this.data.categories);
    Vue.set(this.data, 'categories', ListUtils.sortByKey(this.data.categories, 'label'));
    Vue.set(this, 'loaded', true);
    Vue.set(this, 'originalStatus', this.data.status);
  },
  methods: {
    validateForm () {
      FormUtils.validate(this.$refs['user-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = '/api/video/files/update/' + this.data.id;

      let fileParams = [];
      if (formDataToSend.image) {
        fileParams.push('file');
      }

      let askToSendMail = false;
      if (this.data.status === 'DECLINED' && this.data.status !== this.originalStatus) {
        askToSendMail = true;
      }

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Video saved',
        successText: 'This video has been saved',
        errorTitle: 'An error has occurred',
        errorText: 'Modification of an entry has failed. Please try again.',
        successAction: () => {
          if (askToSendMail) {
            FormUtils.submitWithConfirm(this, {
              endpoint: '/api/video/files/set-status/' + this.data.id,
              formData: {
                id: this.data.id,
                status: this.data.status,
                send_mail: true
              },
              successTitle: 'E-mail sent',
              successText: 'E-mail notification to video creator has been sent',
              confirmTitle: 'Inform creator about status change',
              confirmText: 'Would You like to inform video creator that this video was declined?',
              confirmButtonText: 'Yes',
              errorTitle: 'An error has occurred',
              errorText: 'Notification send failed. Please try again.',
              confirmButtonColor: '#0BBB66'
            });
          }

          this.$bus.$emit('videos-list-reload');
        }
      }, fileParams);
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        id: this.data.id,
        tags: this.data.tags,
        categories: this.data.categories.map(item => item.id),
        status: this.data.status,
        description: this.data.description,
        about_you: this.data.about_you,
        approve_comment: this.data.approve_comment,
        image: this.data.image,
        title: this.data.title,
        ref_code: this.data.ref_code,
        first_name: this.data.video.meta_data.first_name,
        last_name: this.data.video.meta_data.last_name,
        email: this.data.video.meta_data.email,
        mobile: this.data.video.meta_data.mobile,
        town: this.data.video.meta_data.town
      };

      return formDataToSend;
    },
    formatDate (timestamp) {
      if (!timestamp) {
        return 'None';
      }

      let dateObject = new Date(timestamp);

      return [
        dateObject.getHours(),
        dateObject.getMinutes()
      ].map(n => n < 10 ? '0' + n : n).join(':') + ' ' + [
        dateObject.getDate(),
        dateObject.getMonth() + 1,
        dateObject.getFullYear()
      ].map(n => n < 10 ? '0' + n : n).join('/');
    },
    toggleDetailsVisible () {
      Vue.set(this, 'isDetailsVisible', !this.isDetailsVisible);

      if (!this.data.is_archived && this.isDetailsVisible && !this.videoDetailsLoaded) {
        this.loadAdditionalData();
      }
    },
    addCategory () {
      let categoryToAdd = 0;
      if (this.childCategory) {
        categoryToAdd = this.childCategory;
      } else {
        categoryToAdd = this.parentCategory;
      }

      if (!this.data.categories.find(item => item.id === categoryToAdd.id)) {
        this.addLabelForCategorry(categoryToAdd);
        this.data.categories.push(categoryToAdd);
      }

      Vue.set(this, 'parentCategory', null);
      Vue.set(this, 'childCategory', null);
    },
    getChildCatLabel (childCat) {
      let parentCat = this.allCategories.find(item => item.id === childCat.parent_id);

      if (parentCat) {
        return parentCat.name + ' - ' + childCat.name;
      }

      return childCat.name
    },
    addLabelsForCategories () {
      this.data.categories.forEach(cat => {
        this.addLabelForCategorry(cat)
      });
    },
    addLabelForCategorry (category) {
      if (category.parent_id) {
        category.label = this.getChildCatLabel(category);
      } else {
        category.label = category.name;
      }
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/video/files/get-signed-url/' + this.data.id,
        method: 'get',
        outputKey: 'videoDetails',
        loadedKey: 'videoDetailsLoaded',
        errorKey: 'loadError',
        noPagination: true
      });
    },
    formatBytes (bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      let k = 1024;
      let dm = decimals < 0 ? 0 : decimals;
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      let i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / (k ** i)).toFixed(dm)) + ' ' + sizes[i];
    },
    getMetadata (fieldName) {
      if (this.data.video.meta_data[fieldName]) {
        return this.data.video.meta_data[fieldName];
      }
      return '-';
    },
    setThumbnail (event) {
      Vue.set(this.data, 'image', event.target.files[0]);
    },
    removeThumbnail () {
      Vue.set(this.data, 'image', null);
    },
    updateVideoDuration (e) {
      Vue.set(this.data, 'duration', e.target.duration);

      this.$http.post('/api/video/files/set-duration', {
        id: this.data.id,
        duration: e.target.duration
      }).then(response => {
        if (('success' in response.data) && response.data.success === 0) {
          console.log(response.data.message);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>
