<template>
  <div>
    <div class="view-header pt-1">
      <div class="view-header-top flex flex-align-items-center">
        <h2 class="heading-medium">Videos List</h2>

        <div class="videos-stats ml-auto">
          <span class="mr-2">Total videos: <strong>{{ videosStats.all }}</strong></span>
          <span class="mr-2 text-warning">Pending: <strong>{{ videosStats.pending }}</strong></span>
          <span class="mr-2 text-success">Approved: <strong>{{ videosStats.approved }}</strong></span>
          <span class="mr-2 text-danger">Declined: <strong>{{ videosStats.declined }}</strong></span>
        </div>
      </div>

      <div
        v-if="allDataLoaded"
        class="flex flex-column mt-4">

        <validation-observer
          ref="tags-filters-form"
          tag="form"
          class="search-form flex flex-align-items-center">

          <div class="flex">
            <f-input-wrapper
              prependIcon="search"
              class="mr-1">
              <input
                type="search"
                v-model="filters.search"
                placeholder="Search by uploader name, email, phone"/>
            </f-input-wrapper>

            <v-select
              class="mr-1 my-0 status-select-filter"
              :options="allStatuses"
              v-model="filters.status"
              :reduce="(status) => status.id"
              :searchable="true"
              placeholder="Status"
              label="name">
            </v-select>

            <v-select
              class="mr-1 my-0 category-select-filter"
              :options="allCategories"
              v-model="filters.category_id"
              :reduce="(category) => category.id"
              :searchable="true"
              @input="clarChildCat"
              placeholder="Category"
              label="name">
            </v-select>

            <v-select
              class="mr-auto my-0 category-select-filter"
              :disabled="!isChildCatSelectEnabled"
              :options="childCategoriesForSearch"
              v-model="filters.childCategoryId"
              :reduce="(category) => category.id"
              :searchable="true"
              placeholder="Sub category"
              label="name">
            </v-select>
          </div>

          <div class="search-form-buttons flex ml-auto">
            <f-button
              theme="primary"
              :big="true"
              class="m2-1 mb-1-ms ml-0-sm ml-1 text-nowrap"
              @click.prevent="validateFiltersForm">
              Search
            </f-button>

            <f-button
              theme="warning"
              :big="true"
              class="ml-1 text-nowrap"
              :disabled="resetFiltersDisabled"
              @click.prevent="clearFilters">
              Reset filters
            </f-button>
          </div>
        </validation-observer>
      </div>
    </div>

    <div
      v-if="allDataLoaded"
      class="videos-list my-3">
      <videos-list-item
        v-for="(item, index) in videos"
        :key="index"
        :video="item"
        :allStatuses="allStatuses"
        :allCategories="allCategories"
        :allTags="allTags"
        :isEdit="isEdit"/>
      <div v-if="videos.length === 0"
        class="mx-auto my-auto no-results">
        There are no items to display&hellip;
    </div>
    </div>

    <pagination
      :class="{'is-hidden': !allDataLoaded}"
      :meta-data="paginationMetaData" />

    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>


<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import Pagination from '@/components/core/Pagination.vue';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';
import VideosListItem from '@/components/forms/VideosListItem.vue';

export default {
  name: 'videos',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination,
    'videos-list-item': VideosListItem
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.tagsLoaded && this.categoriesLoaded && this.statusesLoaded;
    },
    childCategoriesForSearch () {
      if (this.filters.category_id) {
        return this.allCategories.find(item => item.id === this.filters.category_id).children;
      }

      return [];
    },
    isChildCatSelectEnabled () {
      if (this.filters.category_id) {
        let parentCat = this.allCategories.find(item => item.id === this.filters.category_id)
        return parentCat && parentCat.children && parentCat.children.length;
      }

      return false;
    },
    resetFiltersDisabled () {
      return this.filters.search === '' && this.filters.status === 'PENDING' && !this.filters.category_id && !this.filters.childCategoryId;
    }
  },
  data () {
    return {
      videos: [],
      loaded: false,
      loadError: false,
      removeInProgress: false,
      filters: {
        search: '',
        status: 'PENDING',
        category_id: 0,
        childCategoryId: 0,
      },
      allStatuses: [],
      statusesLoaded: false,
      allTags: [],
      tagsLoaded: false,
      allCategories: [],
      categoriesLoaded: false,
      videosStats: [],
      videosStatsLoaded: false,
      isEdit: true
    }
  },
  mounted () {
    this.$bus.$on('videos-list-reload', this.reloadView);
    this.loadFilteredData();
    this.loadAdditionalData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let requestParams = {
        is_pagination: 1,
        page: this.currentPage
      };

      if (this.filters.search && this.filters.search.length) {
        requestParams.search = this.filters.search;
      }

      if (this.filters.status && this.filters.status.length) {
        requestParams.status = this.filters.status;
      }

      if (this.filters.category_id && this.filters.category_id > 0) {
        requestParams.category_id = this.filters.category_id;
      }

      if (this.filters.childCategoryId && this.filters.childCategoryId > 0) {
        requestParams.category_id = this.filters.childCategoryId;
      }

      ListUtils.loadItemsData(this, {
        endpoint: '/api/video/files/get-all',
        method: 'get',
        listField: 'videos',
        params: requestParams,
      });
    },
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/video/files/statuses',
        method: 'get',
        outputKey: 'allStatuses',
        loadedKey: 'statusesLoaded',
        errorKey: 'loadError',
        noPagination: true
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/video/tag/get-all',
        method: 'get',
        outputKey: 'allTags',
        loadedKey: 'tagsLoaded',
        errorKey: 'loadError',
        params: {
          order_by: 'name',
          order_direction: 'ASC'
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/video/category/get-all',
        method: 'get',
        outputKey: 'allCategories',
        loadedKey: 'categoriesLoaded',
        errorKey: 'loadError',
        params: {
          order_by: 'name',
          order_direction: 'ASC'
        }
      });

      this.loadStats();
    },
    loadStats () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/video/files/get-stats',
        method: 'get',
        outputKey: 'videosStats',
        loadedKey: 'videosStatsLoaded',
        errorKey: 'loadError'
      });
    },
    reloadView () {
      this.loadFilteredData();
      this.loadStats();
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['tags-filters-form'], this.debouncedLoadFilteredData);
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        search: '',
        status: 'PENDING',
        category_id: 0,
        childCategoryId: 0,
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    clarChildCat () {
      Vue.set(this.filters, 'childCategoryId', 0);
    }
  },
  beforeDestroy () {
    this.$bus.$off('videos-list-reload', this.reloadView);
  }
}
</script>

<style lang="scss" scoped>
.category-select-filter,
.status-select-filter {
  width: 20%;
  min-width: 240px;
}

.f-input-wrapper {
  width: 30%;
}
</style>
